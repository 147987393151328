// import './DistOverview.scss'

import { useLocation } from 'react-router-dom'
import { useStateContext } from '../../Context/ContextProvider'
import { useStorageAPI } from '../../Context/ContextProvider/StorageApi'
import { useEffect } from 'react'
import StorageUsage from './StorageUsage'

const REGIONS = {
  VIRGINIA: 'U.S. (N. Virginia)',
  IRELAND: 'Europe (Ireland)',
}

const StorageOverview = () => {
  const { currentStorage, setReloadStorage, currentStorageFetching } =
    useStateContext()
  const location = useLocation()

  useEffect(() => {
    if (location.state?.reload) {
      delete location.state.reload
      setReloadStorage(true)
    }
  }, [location.state?.reload])

  return (
    <>
      <h3 className="content-subtitle mb-40">FlashEdge Storage</h3>
      {/*currentDist?.error && <p>Oh no... {currentDist.error.message}</p>*/}
      {currentStorageFetching && <p>Loading...</p>}
      <div className="input-wrap">
        <p className="content-text">
          To access your storage, please use the remote access tab and follow
          the instructions.
        </p>
        <label className="input-label" htmlFor="originDomain">
          Storage identifier
        </label>
        <input
          className="input md"
          type="text"
          name="awsId"
          id="awsId"
          disabled
          required
          defaultValue={currentStorage?.awsId}
        ></input>
      </div>
      <div className="input-wrap">
        <label className="input-label" htmlFor="originDomain">
          Region
        </label>
        <input
          className="input md"
          type="text"
          name="region"
          id="originDomain"
          disabled
          required
          defaultValue={REGIONS[currentStorage?.region]}
        ></input>
      </div>
      <a
        href="https://docs.flashedgecdn.com/getting-started-with-flashedge/flashedge-set-up-instructions"
        target="_blank"
        className="theme-btn"
      >
        How to connect
      </a>
      {
        <>
          <h3 className="content-subtitle">Statistics</h3>
          <StorageUsage
            name={currentStorage?.name}
            usage={currentStorage?.usage}
          />
          <div className="chart-containers">
            {/*<LineChart DataLine={DataLine} />
        <LineChart DataLine={DataLine} />*/}
          </div>
        </>
      }
    </>
  )
}

export default StorageOverview
